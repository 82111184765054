/*
 * Third party
 */




/*
 * Custom
 */
 


jQuery(document).ready(function() {
	jQuery('.logo').hover(function() {
		jQuery(this).animate({ height: '190', width: '190' }, 450);
		jQuery(this).animate({ height: '178', width: '178' }, 150);
	}, function(){
		
	});
});